import {
  PlusSquareIcon,
  StarIcon,
  ViewIcon,
  HamburgerIcon,
  ChatIcon,
  SunIcon,
  DownloadIcon,
  SearchIcon,
  AddIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Flex,
  List,
  ListItem as ChakraListItem,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGeneralStore } from "../generalStore";
import { mediumWidth, smallWidth } from "../utility/constants";
import StreamlineIcon from "./StreamlineIcon";

const navItems = {
  /* welcome: {
    icon: <StarIcon />,
    text: "Welcome",
  }, */
  home: {
    icon: <StarIcon />,
    text: "Home",
  },
  deals: {
    icon: <SunIcon />,
    text: "Deals",
  },
  /*onboard: {
    icon: <MoonIcon />,
    text: "Goals",
  },*/
  learn: {
    icon: <ViewIcon />,
    text: "Learn",
  },
  /* chat: {
    icon: <ChatIcon />,
    text: "Chat",
  }, */
  /*  shop: {
    icon: <SearchIcon />,
    text: "Shop",
  },
  agents: {
    icon: <SunIcon />,
    text: "Agents",
  }, */

  loans: {
    icon: <HamburgerIcon />,
    text: "My Loans",
  },

  /* insurance: {
    icon: <PlusSquareIcon />,
    text: "Insurance",
  }, */

  /* invest: {
    icon: <ArrowUpIcon />,
    text: "Invest",
  }, */

  /* charities: {
    icon: <ArrowForwardIcon />,
    text: "Donate",
  }, */
};

// if an item has a userIdRoute, a click on the nav item will go to (ex. agent/${userId}), therefore userIdRoute in object will be "agent"

const agentItems = {
  /* welcome: {
    icon: <StarIcon />,
    text: "Welcome",
  }, */
  home: {
    icon: <StarIcon />,
    text: "Home",
  },
  profile: {
    icon: <StreamlineIcon name="user-icon-filled" />,
    text: "Profile",
    usernameRoute: "agent",
  },
  leads: {
    icon: <AddIcon />,
    text: "Leads",
  },
  transactions: {
    icon: <HamburgerIcon />,
    text: "Transactions",
  },
};

const employerItems = {
  home: {
    icon: <StarIcon />,
    text: "Home",
  },
};

type NavItemsType = {
  mobileMenuClose?: () => void;
  isSideNav: boolean;
};

const NavItems: React.FC<NavItemsType> = ({ mobileMenuClose, isSideNav }) => {
  const borrowerId = useGeneralStore((state) => state.borrowerId); // means it is a servicing user
  const currentUser = useGeneralStore((state) => state.currentUser);

  const location = useLocation();

  if (!currentUser) {
    return null;
  }
  const isAgent = Boolean(currentUser?.agent);
  const isEmployer = Boolean(currentUser?.employer);
  const isServicingUser = Boolean(borrowerId);

  let activeSideNav = location?.pathname?.split("/")[1];
  if (activeSideNav && activeSideNav === "dashboard") {
    activeSideNav = "home";
  }

  if (activeSideNav && activeSideNav === "agent") {
    activeSideNav = "profile";
  }

  const userTypeNavItems: any = isAgent
    ? agentItems
    : isEmployer
    ? employerItems
    : navItems;

  return (
    <List p="0" listStyleType="none" m="18px 8px">
      {Object.keys(userTypeNavItems).map((field, i) => {
        if (field === "loans" && !isServicingUser) {
          return null;
        }

        return (
          <ListItem key={i}>
            <ListItemButton
              active={activeSideNav === field}
              field={field}
              icon={userTypeNavItems[field].icon}
              mobileMenuClose={mobileMenuClose}
              showTextAlways={!isSideNav}
              text={userTypeNavItems[field].text}
              userIdRoute={userTypeNavItems[field].userIdRoute}
              usernameRoute={userTypeNavItems[field].usernameRoute}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default NavItems;

const ListItem = ({ children }) => {
  const [isLargerThanMedium] = useMediaQuery(`(min-width: ${mediumWidth})`);
  const [isSmallerThanSmall] = useMediaQuery(`(max-width: ${smallWidth})`);

  return (
    <ChakraListItem
      display="flex"
      alignItems="center"
      pl={isLargerThanMedium || isSmallerThanSmall ? "8px" : undefined}
      h="48px"
      cursor="pointer"
      mb="22px"
    >
      {children}
    </ChakraListItem>
  );
};

type ListItemButtonTypes = {
  active?: boolean;
  field: string;
  icon: any;
  mobileMenuClose?: () => void;
  showTextAlways: boolean;
  text: string;
  userIdRoute: string | undefined;
  usernameRoute: string | undefined;
};

const ListItemButton = ({
  active,
  field,
  icon,
  mobileMenuClose,
  showTextAlways,
  text,
  userIdRoute,
  usernameRoute,
}: ListItemButtonTypes) => {
  const currentUser = useGeneralStore((state) => state.currentUser);
  const setGeneralStoreObj = useGeneralStore((state) => state.setStoreObj);
  const history = useHistory();
  const [isLargerThanMedium] = useMediaQuery(`(min-width: ${mediumWidth})`);
  const [isSmallerThanSmall] = useMediaQuery(`(max-width: ${smallWidth})`);
  const [isSmallerThanMedium] = useMediaQuery(`(max-width: ${mediumWidth})`);

  return (
    <ConditionalWrapper
      condition={
        !isLargerThanMedium && !isSmallerThanSmall && showTextAlways === false
      }
      wrapper={(children) => (
        <Tooltip
          label={text}
          aria-label={text}
          placement="right"
          borderRadius="lg"
        >
          {children}
        </Tooltip>
      )}
    >
      <Flex
        as="a"
        alignItems="center"
        w="100%"
        h="100%"
        role="group"
        _hover={{ bg: "gray.200", borderRadius: "0.5rem" }}
        pl="0.5rem"
        color={active ? "blue.600" : undefined}
        onClick={() => {
          setGeneralStoreObj({ activeSideNav: field });
          if (userIdRoute) {
            history.push(`/${userIdRoute}/${currentUser!.id}`);
          } else if (usernameRoute) {
            history.push(`/${usernameRoute}/${currentUser!.username}`);
          } else {
            history.push(`/${field}`);
          }

          if (mobileMenuClose) {
            mobileMenuClose();
          }
        }}
      >
        <Box
          bg="gray.200"
          h="2rem"
          w="2rem"
          borderRadius="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {icon}
        </Box>

        {(isLargerThanMedium || isSmallerThanSmall || showTextAlways) && (
          <Box
            as="span"
            whiteSpace="nowrap"
            display="flex"
            alignItems="center"
            fontSize="lg"
            fontWeight="600"
            ml="16px"
          >
            {text}
          </Box>
        )}
      </Flex>
    </ConditionalWrapper>
  );
};

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;
