import { createBrowserHistory } from "history";
import ReactGA from "react-ga4";

const history = createBrowserHistory();

history.listen(() => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
});

export default history;
